<script>
import { STORE } from "@/graphql/store/queries.gql";
import VxDialogView from "@/components/vx/VxDialogView";
import InventoryPictures from "./InventoryPictures";
import InventoryItemForm from "./InventoryItemForm";
import RotationCheck from "@/components/inventory/RotationCheck";

import UpdateInventoryItem from "./UpdateInventoryItem.gql";
import InventoryItemQuery from "./InventoryItemQuery.gql";

export default {
  name: "InventoryDetailView",
  components: {
    VxDialogView,
    InventoryPictures,
    InventoryItemForm,
    RotationCheck,
  },
  props: {
    inventoryItemId: {
      type: [Number, String],
      default: "",
    },
    storeId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inventoryItemLoading: 0,

      uploadedImage: null,
      uploadedImages: [],
    };
  },
  computed: {
    formValid() {
      return (
        this.currentStore &&
        (!this.currentStore.camraiConfig.requireLocation ||
          !!this.item.location)
      );
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    formattedInput() {
      const formattedItem = Object.assign({}, this.item);

      delete formattedItem.imageCount;
      delete formattedItem.storeId;
      delete formattedItem.drsSki;
      delete formattedItem.drsCategory;
      delete formattedItem.drsSubcat;
      delete formattedItem.drsBuyTransId;
      delete formattedItem.drsSaleTransId;
      delete formattedItem.invDate;
      delete formattedItem.shopifyOrderNumber;
      delete formattedItem.shopifyDeleted;
      delete formattedItem.shopifyId;
      delete formattedItem.published;
      delete formattedItem.imageCount;
      delete formattedItem.__typename;

      console.log("formated input this.item", this.item);

      return {
        ...formattedItem,
      };
    },
    title() {
      if (this.inventoryItemLoading) {
        return "...";
      }
      return this.item.drsSki;
    },
  },
  apollo: {
    currentStore: {
      query: STORE,
      variables() {
        return {
          id: this.storeId,
        };
      },
      update(data) {
        return data.store;
      },
      skip() {
        return !this.storeId;
      },
    },
    item: {
      loadingKey: "inventoryItemLoading",
      query: InventoryItemQuery,
      variables() {
        return {
          id: this.inventoryItemId,
        };
      },
      update(data) {
        return data.inventoryItem;
      },
    },
  },
  methods: {
    handleImageUpload(file) {
      if (!file.type.includes("image")) {
        // snackbar abt image types
      } else {
        this.uploadedImage = file;
      }
    },
    async processImage(rot, img) {
      var canvas = document.createElement("canvas");
      var height = img.height;
      var width = img.width;
      var maxSize = 2000;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else if (height > width) {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      } else {
        if (width > maxSize) {
          width = maxSize;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;

      var ctx = canvas.getContext("2d");

      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);

      var tempCanvas = document.createElement("canvas");
      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height;
      var tempCtx = tempCanvas.getContext("2d");
      tempCtx.drawImage(canvas, 0, 0);

      if (rot % 2 !== 0) {
        var tmpWidth = canvas.width;
        canvas.width = canvas.height;
        canvas.height = tmpWidth;
      }

      // rotation
      ctx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
      ctx.save();
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((90 * rot * Math.PI) / 180);
      ctx.drawImage(tempCanvas, -tempCanvas.width / 2, -tempCanvas.height / 2);
      ctx.restore();

      canvas.toBlob((blob) => {
        const file = new File([blob], this.uploadedImage.name, {
          type: this.uploadedImage.type,
        });
        this.uploadedImages.push(file);
        this.uploadedImage = null;
      }, this.uploadedImage.type);
    },
    updateInventoryItem(item, sts) {
      this.$apollo
        .mutate({
          mutation: UpdateInventoryItem,
          variables: {
            storeId: this.$route.params.storeId,
            input: {
              ...item,
              images: this.uploadedImages,
            },
            sendToShopify: sts,
          },
          context: {
            hasUpload: !!this.uploadedImages.length,
          },
        })
        .then((data) => {
          this.$router.go(-1);
        });
    },
  },
};
</script>

<template>
  <VxDialogView
    :title="title"
    :v-size="'large'"
    :h-size="'small'"
    :retain-focus="false"
  >
    <template #actions>
      <v-btn
        :color="isSmallScreen ? null : 'primary'"
        text
        :disabled="!formValid"
        @click="updateInventoryItem(formattedInput)"
        >Update</v-btn
      >
    </template>
    <template v-if="item">
      <template v-if="!uploadedImage">
        <InventoryPictures
          :uploaded-images="uploadedImages"
          :count="item.imageCount"
          :item-id="item.id"
          @upload="handleImageUpload"
        />
        <InventoryItemForm :item="item" />
      </template>
      <template v-else>
        <RotationCheck :image-file="uploadedImage" @confirm="processImage" />
      </template>
    </template>
  </VxDialogView>
</template>

<style></style>
